<template>
  <div>
    <v-container
      class="grey lighten-5 mb-6"
    >
      <v-row>
        <v-col><strong>{{ title }}</strong></v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          sm="10"
          md="10"
          lg="10"
        >
          <v-progress-linear
            class="mt-1"
            :value="percentValue"
            height="15"
            :rounded="true"
          >
            <strong>{{ percentValue }}%</strong>
          </v-progress-linear>
        </v-col>
        <v-col
          sm="2"
          md="2"
          lg="2"
        >
          <div class="text-center">
            {{ rawValue }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mdiInformationVariant } from '@mdi/js'

export default {
  props: {
    rawValue: { type: [String, Number], default: 0 },
    percentValue: { type: [String, Number], default: 0 },
    title: { type: [String, Number], default: 0 },
  },
  setup() {
    return {
      mdiInformationVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
